import React  from "react";
import { Link } from "gatsby"

import SolutionList from "../components/solutions/list";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Contact from "../components/contact";
import Office from "../components/office";

const ServicesPage = () => {
    return (
        <Layout>
            <SEO title={`Our Services`} />

            <div className="o-container">
                <h1 className="u-h1 u-c o-heading">Our <strong>services</strong></h1>

                <p className="u-l u-mb--xxl">We offer a broad range of development services.</p>

                <SolutionList />

                <h2 className="u-h3">How we can work together</h2>

                <ul className={"o-list o-list--spacer o-list--split o-list--border"}>
                    <li className="o-list__item">
                        <h3>Project based</h3>
                        <p>Lacking in-house digital expertise or capacity? Once the scope is defined the project can be executed in a fixed price agreement.</p>
                    </li>
                    <li className="o-list__item">
                        <h3>Timesheet based</h3>
                        <p>Transparent way of working for long term projects and when working in close cooperating together.</p>
                    </li>
                    <li className="o-list__item">
                        <h3>Consultancy</h3>
                        <p>Looking for dedicated team members? We have digital experts available for mid and long-term consultancy assignments.</p>
                    </li>
                    <li className="o-list__item">
                        <h3>Joint venture</h3>
                        <p>Got the next big thing on your mind? Get in touch, we are eager to discuss new business opportunities.</p>
                    </li>
                </ul>

                {false && (
                <div className={"c-cta"}>
                    <div className="c-cta__wrapper">
                        <h2 className={"c-cta__title"}>Omnichannel Experience</h2>
                        <p>Streamlined user experience across all your digital channels.</p>
                        <Link to={"/omnichannel-experience"} className={"c-button"}>Discover how it works</Link>
                    </div>
                </div>
                )}

                <Contact />
                <Office/>
            </div>
        </Layout>
    );
};

export default ServicesPage;
